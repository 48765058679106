.divider {
	width: 100%;
	height: 2px;
	margin-bottom: var(--hdsplus-spacing-07);

	&.neutral {
		height: 1px;
		background: var(--token-color-border-primary);
	}
	
	&.ilm {
		background: linear-gradient(to right, var(--gradient-infrastructure-lifecycle-management));
	}
	
	&.slm {
		background: linear-gradient(to right, var(--gradient-security-lifecycle-management));
	}

	&.hasEyebrow {
		margin-bottom: var(--hdsplus-spacing-04);
	}
}

.eyebrow {
	display: block;
	font-weight: 500;
	padding-bottom: var(--hdsplus-spacing-04);
}

.products {
	margin: 0;
	padding: 0;
	list-style-type: none;
	display: flex;
	flex-wrap: wrap;
	gap: var(--hdsplus-spacing-03);
}

.product {
	display: block;
	padding: var(--hdsplus-spacing-04);
}

.productLink {
	composes: product;
	border: 1px solid var(--token-color-border-faint);
	border-radius: var(--hdsplus-spacing-02);
}
